/*ADD FONTS*/
@font-face {
  font-family: "Lucky Fellas";
  src: url("./fonts/Lucky-Fellas-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./fonts/Poppins-Bold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DancingScript";
  src: url("./fonts/DancingScript-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DancingScript Medium";
  src: url("./fonts/DancingScript-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DancingScript SemiBold";
  src: url("./fonts/DancingScript-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DancingScript Bold";
  src: url("./fonts/DancingScript-Bold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*MAIN CSS*/
html {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

/*GLOBAL FORM STYLING*/
.form-control {
  border: none;
  border-radius: 0px;
  border-bottom: 1.21333px solid rgba(8, 63, 136, 0.14);
}

/*GLOBAL NAVBAR STYLING*/

.navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url('./images/HamBurger_close.svg');
  background-size: 165%;
  transition: background-image 0.1s linear;

}

.navbar-light .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("./images/hamburger_menu.svg");
  background-size: 100%;
  background-color: transparent;
  transition: background-image 0.1s linear;
}

.navbar-light .navbar-toggler.collapsed {
  border: none;
  margin-right: 5px;
  margin-top: -5px;
}
.navbar-light .navbar-toggler:focus {
  box-shadow: 0 0 0 0.1rem;
}


.custom-arrow.form-select {
  border: none;
  padding-right: 30px;
  width: 102%;
}
.custom-arrow-join.form-select {
  border: none;
  padding-right: 42px;
}

.custom-arrow-join {
  transform: translateX(-10px);
}

.custom-border-bottom {
  border-bottom: 1.21px solid rgba(8, 63, 136, 0.14);
  border-radius: 0;
}

.custom-arrow-join.is-invalid + .custom-border-bottom {
  border-color: #dc3545;
}

.form-select-border-parent > *.is-invalid:nth-child(1) {
  border-color: #dc3545;
}

.form-select.custom-arrow ,
.form-select.custom-arrow-join {
  background-position: right 0.2rem center;
}

.form-select.custom-arrow.is-invalid:not([multiple]):not([size]) ,
.form-select.custom-arrow-join.is-invalid:not([multiple]):not([size]) {
  background-position: right 1.75rem center,center right 0.25rem;
}

@media (width > 992px) {
  #nav_collapse {
    visibility: hidden;
  }
}

/*DECORATIONS*/
.App {
  background-repeat: no-repeat;
  background-image: url("./images/decorations/deco_logo.svg"),
    url("./images/decorations/deco_blue_blob.svg"),
    url("./images/decorations/deco_swirl_orange.svg"),
    url("./images/decorations/deco_orange_blob.svg"),
    url("./images/decorations/right_side/deco_orange_blob_right.svg"),
    url("./images/decorations/right_side/deco_swirl_orange_right.svg"),
    url("./images/decorations/right_side/deco_logo_right.svg"),
    url("./images/decorations/right_side/deco_orange_blob_right.svg"),
    url("./images/decorations/right_side/deco_swirl_orange_right2.svg");
  background-position: 0% 20%, 0% 30%, 0% 50%, 0% 80%, right 0% top 20%,
    right 0% top 35%, right 0% top 50%, right 0% top 60%, right 0% top 70%;
}

@media (width < 992px) {
  .App {
    background-image: none;
  }

  .App {
    background-image: url("./images/decorations/deco_swirl_blue.svg"),
      url("./images/decorations/right_side/deco_orange_blob_right.svg");
    background-position: 0% 46%, 100% 45%;
    background-size: 20%, 20%;
  }
}

@media (width < 768px) {
  .App {
    background-image: none;
  }

  .App {
    background-image: url("./images/decorations/deco_swirl_blue.svg"),
      url("./images/decorations/right_side/deco_orange_blob_right.svg");
    background-position: 0% 48%, 100% 45%;
    background-size: 20%, 20%;
  }

  .form-select.custom-arrow ,
  .form-select.custom-arrow-join {
    background-position: right 0.2px center;
    width: 103%;
  }

}

.scroll_ref {
  display: block;
}
.mobile_scroll_ref {
  display: none;
}

.form-main-div .invalid-feedback {
  text-align: left;
}

.form-group-div {
  margin-bottom: 5px;
}

@media (max-width:1000px) {
  /** Hide desktop scroller ref element in mobile view,
  and enable mobile ref */
  .scroll_ref {
    display: none;
  }
  .mobile_scroll_ref {
    display: block;
  }

}

@media (min-width: 1000px) and (max-width:1500px) {
  .custom-arrow.form-select {
    width: 103%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-arrow-join.form-select {
    width: 102%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (min-width: 1000px) and (max-width:1250px) {

  .custom-arrow-join.form-select {
    width: 102%;
  }
}

.nav-link {
  padding: 0.5rem 1rem !important;
  
}
@media (min-width: 320px) and (max-width:575px) {
  .nav_link {
    font-size: 14px !important;
  }
}


.container {
  touch-action: none !important;
}

.nav_links {
  margin: auto 6%;
  text-shadow: 1px 1px 1px white, 1px 1px 1px white;
  color: #083f88;
  border-right-style: solid !important;
  border-right-color: #083f88;
  border-left-width: 2px;
}

.bg-light{
  --bs-bg-opacity: 1;
    background-color: white !important;
}

@media (max-width: 377px) {
  
  .navbar-light .navbar-toggler.collapsed {
    border: none;
    margin-right: 0px !important;
    margin-top: -5px;
}
}

@media screen and (min-width:992px){
  .userwaydivmobile {
    display:none;
  }
}

.userwaydivmobile {
  padding-bottom: 1rem;
}