body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 424.7px) {
  
  body .uwy .uai,
  .uwy .uai img:not(.check_on),
  body .uwy .uai img:not(.check_on) {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    max-width: 30px !important;
    max-height: 30px !important;
    padding: 2px !important;
  }
}

/* @media only screen and (width: 320px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 12px !important;
        left: 250px !important;
      }
    }

    @media only screen and (min-width: 321px) and (max-width: 350.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 12px !important;
        left: 252px !important;
      }
    }

    @media only screen and (min-width: 351px) and (max-width: 361.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 12px !important;
        left: 260px !important;
      }
    }
    @media only screen and (min-width: 362px) and (max-width: 377.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 12px !important;
        left: 270px !important;
      }
    }
    @media only screen and (min-width: 378px) and (max-width: 390.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 12px !important;
        left: 305px !important;
      }
    }

    @media only screen and (min-width: 391px) and (max-width: 424.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 12px !important;
        left: 315px !important;
      }
    }

    @media only screen and (min-width: 425px) and (max-width: 600.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 340px !important;
      }
    }

    @media only screen and (min-width: 601px) and (max-width: 660.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 340px !important;
      }
    }

    @media only screen and (min-width: 661px) and (max-width: 710.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 340px !important;
      }
    }

    @media only screen and (min-width: 711px) and (max-width: 740.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 360px !important;
      }
    }

    @media only screen and (min-width: 741px) and (max-width: 767.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 380px !important;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 820.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 350px !important;
      }
    }

    @media only screen and (min-width: 821px) and (max-width: 900.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 350px !important;
      }
    }

    @media only screen and (min-width: 901px) and (max-width: 960.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 380px !important;
      }
    }

    @media only screen and (min-width: 961px) and (max-width: 991.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 400px !important;
      }
    } */

    @media only screen and (min-width: 992px) and (max-width: 1023.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 500px !important;
      }
    }
    
    

    /* if device has a touch screen */
    @media (any-pointer: coarse) {
      @media only screen and (min-width: 1024px) and (max-width: 1040.7px) {
        body .uwy.userway_p3 .uai {
          top: 5px !important;
          left: 570px !important;
        }
      }
    }

    /* if device has no touch screen */
    @media (any-pointer: fine) {
      @media only screen and (min-width: 1024px) and (max-width: 1040.7px) {
        body .uwy.userway_p3 .uai {
          top: 5px !important;
          left: 570px !important;
        }
      }
    }
    @media only screen and (min-width: 1040.7px) and (max-width: 1099.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 610px !important;
      }
    }

    @media only screen and (min-width: 1100px) and (max-width: 1680.7px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 720px !important;
      }
    }

    @media only screen and (min-width: 1681px) and (max-width: 1999.7px){
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 750px !important;
      }
    }

    @media only screen and (min-width: 2000px) and (max-width: 2350.7px){
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 1200px !important;
      }
    }

    @media only screen and (min-width: 2351px) {
      body .uwy.userway_p3 .uai,
      body .uwy.userway_p7 .uai, 
      body .uwy.userway_p8 .uai {
        top: 5px !important;
        left: 1300px !important;
      }
    }
 